<template>
  <div>
    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          TÀI LIỆU ĐÍNH KÈM
        </b-badge>
      </b-card-header>
      <b-card-body>
        <div v-if="contract.document_files && contract.document_files.length">
          <h5 class="text-primary mb-1">
            <b-badge
              variant="light-primary"
              size="sm"
            >
              Hình ảnh
            </b-badge>
            (<strong>{{ imgFiles.length }}</strong>)
          </h5>
          <viewer
            :images="imgFiles"
            class="viewer-wrap mb-2"
          >
            <div
              v-for="(file, idx) in imgFiles"
              :key="idx"
              class="viewer-item position-relative"
            >
              <div class="viewer-item-top">
                {{ file.name }}
              </div>
              <div class="viewer-item-icon">
                <div>
                  <feather-icon
                    icon="ExternalLinkIcon"
                    size="24"
                    @click="openLinkInNewTab(file.origin)"
                  />
                  <feather-icon
                    icon="DownloadIcon"
                    size="24"
                    @click="downloadUrl(file.origin, file.name)"
                  />
                </div>
                <feather-icon
                  icon="EditIcon"
                  size="24"
                  @click="openModalEditName(file)"
                />
              </div>
              <img
                :src="file.large"
                class="h-100 w-100"
                :alt="file.name"
              >
            </div>
          </viewer>
          <hr>
          <div
            v-if="pdfFiles.length"
            class="list-file-doc"
          >
            <h5 class="text-primary mb-1">
              <b-badge
                variant="light-primary"
                size="sm"
              >
                Pdf
              </b-badge>
              (<strong>{{ pdfFiles.length }}</strong>)
            </h5>
            <div
              v-for="(file, idx) in pdfFiles"
              :key="idx"
              class="viewer-item position-relative text-primary cursor-pointer d-flex mb-1"
            >
              <div
                @click="showPreviewFile(file, 'pdf')"
              >
                <b-img
                  src="@/assets/images/icons/icon-pdf.png"
                  alt="icon pdf"
                  class="mr-1"
                  width="20px"
                />
                {{ file.name }}
              </div>
              <feather-icon
                icon="DownloadIcon"
                class="ml-1"
                size="20"
                @click="downloadUrl(file.origin, file.name)"
              />
              <feather-icon
                icon="EditIcon"
                class="ml-1"
                size="20"
                @click="openModalEditName(file)"
              />
            </div>
            <hr>
          </div>
          <div
            v-if="docFiles.length"
            class="list-file-doc"
          >
            <h5 class="text-primary mb-1">
              <b-badge
                variant="light-primary"
                size="sm"
              >
                Doc
              </b-badge>
              (<strong>{{ docFiles.length }}</strong>)
            </h5>
            <div
              v-for="(file, idx) in docFiles"
              :key="idx"
              class="viewer-item position-relative text-primary cursor-pointer d-flex mb-1"
            >
              <div
                @click="showPreviewFile(file, 'doc')"
              >
                <b-img
                  src="@/assets/images/icons/icon-doc.png"
                  alt="icon doc"
                  class="mr-1"
                  width="20px"
                />
                {{ file.name }}
              </div>
              <feather-icon
                icon="DownloadIcon"
                class="ml-1"
                size="20"
                @click="downloadUrl(file.origin, file.name)"
              />
              <feather-icon
                icon="EditIcon"
                class="ml-1"
                size="20"
                @click="openModalEditName(file)"
              />
            </div>
            <hr>
          </div>
        </div>
        <div v-else>
          Chưa có tài liệu đính kèm
        </div>
      </b-card-body>
    </b-card>

    <div
      v-if="isShowPdfPreview && currentFilePdfUrl"
    >
      <div
        class="preview-file file-pdf"
      >
        <div
          v-show="pdfProgress < 100"
          class="pdf-loading"
        >
          <div class="w-100 h-100 d-flex flex-column justify-content-center">
            <label>{{ pdfProgress === 0 ? 'Đang tải dữ liệu...' : 'Đang chuyển đổi dữ liệu...' }}</label>
            <b-progress
              max="100"
            >
              <b-progress-bar :value="pdfProgress">
                <strong>{{ pdfProgress }} %</strong>
              </b-progress-bar>
            </b-progress>
          </div>
        </div>

        <div style="z-index: 9999">
          <feather-icon
            class="icon-close text-danger"
            icon="XIcon"
            size="48"
            @click="isShowPdfPreview=false"
          />
          <pdf
            v-for="i in numPagesCurrentPdf"
            :key="i"
            :src="currentFilePdfUrl"
            class="mb-1"
            :page-number="i"
            :page="i"
            @loaded="pdfLoaded = true"
            @progress="p => handlePdfProgress(p, i)"
          />
        </div>
      </div>
    </div>

    <div
      v-if="isShowDocPreview && fileDocUrl"
      class="preview-file file-doc"
    >
      <feather-icon
        class="icon-close text-danger"
        icon="XIcon"
        size="48"
        @click="isShowDocPreview=false"
      />
      <VueDocPreview
        :value="fileDocUrl"
        type="office"
      />
    </div>

    <!-- modal -->
    <b-modal
      id="modal-edit-file-name"
      ref="modal-edit-file-name"
      title="Chỉnh sửa tên file"
      ok-title="Cập nhật"
      cancel-title="Hủy"
      @ok="handleUpdateFileName"
    >
      <p>
        <label>Tên cũ:</label> <span class="text-primary">{{ currentFileEdit.name }}</span>
      </p>
      <b-form-group
        label="Tên mới"
        label-for="new_name"
      >
        <b-input-group class="input-group-merge">
          <b-form-input
            id="new_name"
            v-model="newFileName"
            type="text"
          />
          <b-input-group-append
            v-if="currentFileEdit.name"
            is-text
          >
            .{{ currentFileEdit.name.split('.').pop() }}
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import pdf from 'vue-pdf'
import VueDocPreview from 'vue-doc-preview'
import {
  BBadge, BCard, BCardBody, BCardHeader, BImg, BProgress, BProgressBar, VBToggle,
  BFormGroup, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BBadge,
    BCardBody,
    BImg,
    BProgress,
    BProgressBar,
    VueDocPreview,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    pdf,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    contract: {
      type: Object,
      default: null,
      required: true,
    },
  },
  methods: {
    handlePdfProgress(percent, idx) {
      this.pdfProgressPerPage[idx] = percent

      let totalPercent = 0
      Object.keys(this.pdfProgressPerPage)
        .forEach(key => {
          totalPercent += this.pdfProgressPerPage[key]
        })
      this.pdfProgress = Math.floor((totalPercent * 100) / this.numPagesCurrentPdf)
    },
    handleUpdateFileName() {
      const found = this.contract.document_files.find(item => item.filename === this.currentFileEdit.filename)
      found.name = `${this.newFileName}.${this.currentFileEdit.name.split('.').pop()}`
      this.$emit('update-document-files')
    },
    openModalEditName(obj) {
      this.currentFileEdit = obj || {}
      this.newFileName = obj.name.split('.')[0] || ''
      this.$refs['modal-edit-file-name'].show()
    },
    showPreviewFile(file, type = 'pdf') {
      if (type === 'pdf') {
        this.currentFilePdfUrl = file.origin
        this.isShowPdfPreview = true
      }
      if (type === 'doc') {
        this.fileDocUrl = file.origin
        this.isShowDocPreview = true
      }
    },
  },
  setup(props) {
    const toast = useToast()

    const IMAGE_TYPE = 'png gif jpg jpeg jfif pjpeg pjp svg webp apng'
    const PDF_TYPE = 'pdf'
    const DOC_TYPE = 'doc docx'

    const pdfLoaded = ref(false)
    const pdfProgressPerPage = ref({})
    const pdfProgress = ref(0)
    const numPagesCurrentPdf = ref(null)
    const currentFilePdfUrl = ref('')
    const loadingCurrentPdfTask = ref(null)
    const isShowPdfPreview = ref(false)

    const fileDocUrl = ref('')
    const currentFileEdit = ref({})
    const newFileName = ref('')
    const isShowDocPreview = ref(false)

    const imgFiles = computed(() => {
      const files = props.contract.document_files || []
      const imgs = files.filter(f => IMAGE_TYPE.split(' ')
        .includes(f.filename.split('.')
          .pop().toLowerCase()))
      return imgs || []
    })

    const maxLengthName = computed(() => (window.innerWidth > 768 ? 50 : 15))

    const pdfFiles = computed(() => {
      const files = props.contract.document_files || []
      const pdfs = files.filter(f => PDF_TYPE.split(' ')
        .includes(f.filename.split('.')
          .pop().toLowerCase()))
      return pdfs || []
    })

    const docFiles = computed(() => {
      const files = props.contract.document_files || []
      const docs = files.filter(f => DOC_TYPE.split(' ')
        .includes(f.filename.split('.')
          .pop().toLowerCase()))
      return docs || []
    })

    const tableColumns = [
      {
        label: 'STT',
        key: 'stt',
      },
      {
        label: 'Ngày thu',
        key: 'ngay-thu',
      },
      {
        label: 'Đội',
        key: 'doi',
      },
      {
        label: 'Số tiền thu được',
        key: 'so-tien-thu-duoc',
      },
      {
        label: 'Phí DV',
        key: 'phi-dv',
      },
      {
        label: 'Tổng PDV',
        key: 'tong-pdv',
      },
      {
        label: 'Chi đội',
        key: 'chi-doi',
      },
      {
        label: 'Công đoàn 0.5%',
        key: 'cong-doan',
      },
      {
        label: 'Lái xe 1%',
        key: 'lai-xe',
      },
      {
        label: 'Trả lại lái xe',
        key: 'tra-lai-lai-xe',
      },
      {
        label: 'Giữ lại',
        key: 'giu-lai',
      },
      {
        label: 'Quỹ đội',
        key: 'quy-doi',
      },
      {
        label: 'Chi đội',
        key: '95-chi-doi',
      },
      {
        label: '',
        key: 'actions',
      },
    ]

    watch([currentFilePdfUrl], () => {
      pdfProgressPerPage.value = {}
      pdfLoaded.value = false
      loadingCurrentPdfTask.value = pdf.createLoadingTask(currentFilePdfUrl.value)
      loadingCurrentPdfTask.value.promise.then(p => {
        numPagesCurrentPdf.value = p.numPages
      }).catch(e => {
        isShowPdfPreview.value = false
        toast({
          component: ToastificationContent,
          props: { title: e.message, variant: 'danger' },
        })
      })
    })

    return {
      newFileName,
      currentFileEdit,
      tableColumns,
      pdfLoaded,
      maxLengthName,
      pdfProgressPerPage,
      pdfProgress,
      numPagesCurrentPdf,
      loadingCurrentPdfTask,
      currentFilePdfUrl,
      isShowPdfPreview,
      fileDocUrl,
      isShowDocPreview,
      imgFiles,
      pdfFiles,
      docFiles,
    }
  },
}
</script>

<style lang="scss">

.viewer-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(132px, 150px));
  grid-auto-rows: 1fr;
  gap: .5rem;

  .viewer-item {
    width: 100%;
    height: 100%;
    min-height: 132px;
    max-height: 150px;
    object-fit: cover;
    border: 2px solid white;
    box-shadow: 0 0 10px 0 rgb(34 41 47 / 20%);
    transition-duration: .3s;
    cursor: pointer;

    &:hover {
      box-shadow: 0 4px 24px 0 rgb(34 41 47);

      .viewer-item-icon {
        background-color: rgba(115, 103, 240, .9) !important;
        color: white !important;
      }
    }

    .viewer-item-top {
      position: absolute;
      top: 0;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      color: #fff;
      background: #0000004d;
    }

    .viewer-item-icon {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-left: .5rem;
      transition-duration: .3s;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > *:not(last-child) {
        margin-right: .5rem;
      }

      img {
        object-fit: cover;
      }
    }
  }
}

.preview-file {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5% 10%;
  left: 0;
  top: 0;
  z-index: 9999;

  .icon-close {
    background-color: ghostwhite;
    box-shadow: 0 0 10px 0 rgba(black, 0.3);
    transition-duration: .3s;
    cursor: pointer;
    padding: .5rem;
    border-radius: 1rem;
    border: 2px solid white;
    position: fixed;
    top: 5%;
    right: 10%;
    transform: translate(50%, -50%);
    z-index: 9999;

    &:hover {
      box-shadow: 0 0 10px 0 black;
    }
  }
}

[page-number] {
  position: relative;

  &:before {
    content: "- Trang " attr(page-number) " -";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: "- Trang " attr(page-number) " -";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.pdf-loading {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9998;
  background: white;
  min-width: 30%;
  padding: 2rem 1rem;
  border-radius: 1rem;
}

.dc-top {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
</style>
